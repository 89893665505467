import React from "react";
import { Link } from "react-router-dom";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import firebase from "firebase";
import QRCode from "react-qr-code";

// ***Page Description***
// QR Code Page that is shown to officers to get checkedIn 
// ***********************
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 0,
      events: [], // Store events in the state
      selectedEvent: "" // Track the selected event
    };
    this.listener = null;
  }

  refresh = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => { })
      .catch(function (error) {
        alert("Activation error. Try signing out and signing in again.");
      });
  };

  componentDidMount = () => {
    this.listener = firebase.database().ref("credits/" + firebase.auth().currentUser.email.replace(/\./g, ",") + "/adminCheckedIn");

    this.listener.on("value", (snapshot) => {
      console.log("adminCheckIn val: " + snapshot.val());
      if (snapshot.val() == null || snapshot.val() == 0) {
        this.setState({ status: 0 });
      } else if (snapshot.val() == 1) {
        this.setState({ status: 1 });
      } 
    });

    // User-specific check-in logic here (existing code for "wos3")
  };

  componentWillUnmount = () => {
    if (this.listener) {
      this.listener.off();
    }
  }

  // Function to handle dropdown change
  handleEventChange = (event) => {
    this.setState({ selectedEvent: event.target.value });
    console.log(this.state)
  };

  textSelector = () => {
    if (this.state.status == 1) {
      return "You are checked into this event! Be sure to scan out after the event concludes. Thanks!";
    } else if (this.state.status == 2) {
      return "Welcome to Week of Service Day 3 - enjoy!";
    } else if (this.state.status == 3) {
      return "You are all set! You will receive service hours for this event no later than 1 week from the date of the event.";
    } else if (this.state.status == 0) {
      return "Loading.";
    }
  };

  render() {
    return (
      <main
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6 h-full"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">Activation</h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div className="flex flex-col">
              <div
                className={
                  this.state.status == 1
                    ? "bg-green-600 rounded-lg"
                    : this.state.status == 0
                      ? "hidden"
                      : null
                }
              >
                <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                  <div className="flex items-center justify-between flex-wrap">
                    <div className="w-0 flex-1 flex items-center">
                      <span
                        className={
                          this.state.status == 1
                            ? "bg-green-800 flex p-2 rounded-lg"
                            : this.state.status == 0
                              ? "hidden"
                              : null
                        }
                      >
                        <svg
                          className="h-6 w-6 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                          />
                        </svg>
                      </span>
                      <p className="ml-3 font-medium text-white">
                        <span className="md:hidden">{this.textSelector()}</span>
                        <span className="hidden md:inline">
                          {this.textSelector()}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white shadow sm:rounded-lg mt-4">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mt-4">
                  <svg
                    className="h-6 w-6 text-green-600"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Get Checked In
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm leading-5 text-gray-500 px-4">
                      Please show the following QR code to an officer to get
                      service hours for this event.
                    </p>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 mb-6 flex justify-center align-center">
                  <span className="flex w-full rounded-md align-center justify-center">
                    <QRCode value={firebase.auth().currentUser.uid} level="Q" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
