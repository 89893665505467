import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase";

// ***Page Description***
// Page that takes in user information when they log in
// Stored in "reg" of database 
// ***********************
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pname: "",
      idnumber: "",
      emailaddress: "",
      phone: "",
      gradelevel: "",
      tshirt: "",
      firstname: firebase.auth().currentUser.displayName.split(" ")[0],
      lastname: firebase
        .auth()
        .currentUser.displayName.replace(
          firebase.auth().currentUser.displayName.split(" ")[0] + " ",
          ""
        ),
      membertype: "",
      eventtype: "",
      pisd: firebase.auth().currentUser.email,
      uid: firebase.auth().currentUser.uid,
      emailNotif: true,
      smsNotif: true,
      pushType: 1,
      duesAgree: false,
      bylawsAgree: false,
      classroomAgree: false,
      acintegrityAgree: false,
    };
  }


  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true }, () => {
      firebase
        .database()
        .ref("reg2025/" + firebase.auth().currentUser.email.replace(/\./g, ","))
        .set(this.state)
        .then(() => {
          console.log('successfully set!');
        });

      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
          console.log(idToken);
          fetch("https://api.peshnhs.org/requestActivation", {
            headers: {
              idtoken: idToken,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
                console.log(idToken);
                firebase
                  .auth()
                  .currentUser.getIdToken(true)
                  .then(function (newToken) {
                    // firebase.auth().signInAndRetrieveDataWithCredential
                    // firebase.auth().signInWithCustomToken(idToken);
                  });
              }
            })
            .catch((error) => {
              alert("Activation error. Try signing out and signing in again.");
            });
        })
        .catch(function (error) {
          alert("Activation error. Try signing out and signing in again.");
        });
    });
  };

  handleChanges = (e) => {
    console.log(e.target.value, e.target.checked, e.target.type);
    if (e.target.type == "radio" || e.target.type == "checkbox") {
      if (e.target.name == "pushType") {
        this.setState({ [e.target.name]: e.target.value }, () => {
          console.log(this.state);
          firebase
            .database()
            .ref("reg2025/" + firebase.auth().currentUser.email.replace(/\./g, ","))
            .set(this.state);
        });
      } else {
        this.setState({ [e.target.id]: e.target.checked }, () => {
          console.log(this.state);
          firebase
            .database()
            .ref("reg2025/" + firebase.auth().currentUser.email.replace(/\./g, ","))
            .set(this.state);
        });
      }
    } else {
      this.setState({ [e.target.id]: e.target.value }, () => {
        console.log(this.state);
        console.log(firebase.auth().currentUser.email.replace(/\./g, ","));
        firebase
          .database()
          .ref("reg2025/" + firebase.auth().currentUser.email.replace(/\./g, ","))
          .set(this.state);
      });
    }
  };
  render() {
    return (
      <main
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">Registration</h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <form onSubmit={this.handleSubmit}>
            <div className="py-4">
              <div>
                <div class="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Personal Information
                      </h3>
                      <p class="mt-1 text-sm leading-5 text-gray-500">
                        Please use a personal email account that you can receive
                        mail at.
                      </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="first_name"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            First Name
                          </label>
                          <input
                            required
                            id="firstname"
                            name="firstname"
                            value={this.state.firstname}
                            onChange={this.handleChanges}
                            class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="first_name"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Last Name
                          </label>
                          <input
                            required
                            id="lastname"
                            name="lastname"
                            value={this.state.lastname}
                            onChange={this.handleChanges}
                            class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="last_name"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Student ID
                          </label>
                          <input
                            required
                            id="idnumber"
                            name="idnumber"
                            type="text"
                            pattern="\d*"
                            minlength="6"
                            maxlength="6"
                            value={this.state.idnumber}
                            onChange={this.handleChanges}
                            class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-4">
                          <label
                            for="email_address"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Personal Email Address
                          </label>
                          <input
                            required
                            id="emailaddress"
                            name="emailaddress"
                            type="email"
                            value={this.state.emailaddress}
                            onChange={this.handleChanges}
                            class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-4">
                          <label
                            for="phone"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Cell Phone Number
                          </label>
                          <input
                            required
                            id="phone"
                            name="phone"
                            type="text"
                            pattern="\d*"
                            minlength="10"
                            maxlength="10"
                            value={this.state.phone}
                            onChange={this.handleChanges}
                            class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label class="block text-sm font-medium leading-5 text-gray-700">
                            Grade Level
                          </label>
                          <select
                            required
                            id="gradelevel"
                            name="gradelevel"
                            value={this.state.gradelevel}
                            onChange={this.handleChanges}
                            class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          >
                            <option></option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                          </select>
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="country"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            T-Shirt Size
                          </label>
                          <select
                            required
                            id="tshirt"
                            name="tshirt"
                            value={this.state.tshirt}
                            onChange={this.handleChanges}
                            class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          >
                            <option></option>
                            <option>S</option>
                            <option>M</option>
                            <option>L</option>
                            <option>XL</option>
                            <option>XXL</option>
                          </select>
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="country"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Induction Status
                          </label>
                          <select
                            required
                            id="membertype"
                            name="membertype"
                            value={this.state.membertype}
                            onChange={this.handleChanges}
                            class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          >
                            <option></option>
                            <option value={true}>
                              I have been inducted at any NHS chapter.
                            </option>
                            <option value={false}>
                              I have not been inducted.
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Chapter Information
                      </h3>
                      <p class="mt-1 text-sm leading-5 text-gray-500">
                        Please read the following and confirm acceptance of our
                        bylaws.
                      </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <fieldset class="mt-6">
                        <legend class="text-base leading-6 font-medium text-gray-900">
                          ANNUAL MEMBERSHIP DUES
                        </legend>
                        <p class="text-sm leading-5 text-gray-500">
                          All members must pay this year’s reduced membership
                          dues of $18. The dues include a long-sleeve
                          NHS T-shirt. The remainder of the dues covers the
                          costs of major chapter events and service projects
                          open to all members. Show the QR code that appears after submitting this form, along with your form and cash/check in order to activate your portal account.
                        </p>
                        <div class="mt-4">
                          <div class="flex items-center">
                            <input
                              required
                              type="radio"
                              class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                              id="duesAgree"
                              name="duesAgree"
                              checked={this.state.duesAgree}
                              onChange={this.handleChanges}
                            />
                            <label for="push_everything" class="ml-3">
                              <span class="block text-sm leading-5 font-medium text-gray-700">
                                I agree
                              </span>
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset class="mt-6">
                        <legend class="text-base leading-6 font-medium text-gray-900">
                          Chapter Bylaws & Policies Agreement
                        </legend>
                        <p class="text-sm leading-5 text-gray-500">
                          The Plano East NHS bylaws—outlining chapter policies
                          and procedures—are linked on the homepage of our
                          website, peshnhs.org. Please read the bylaws with your
                          parent(s).
                        </p>
                        <div class="mt-4">
                          <div class="flex items-center">
                            <input
                              required
                              type="radio"
                              class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                              id="bylawsAgree"
                              name="bylawsAgree"
                              checked={this.state.bylawsAgree}
                              onChange={this.handleChanges}
                            />
                            <label for="push_everything" class="ml-3">
                              <span class="block text-sm leading-5 font-medium text-gray-700">
                                I agree
                              </span>
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset class="mt-6">
                        <legend class="text-base leading-6 font-medium text-gray-900">
                          Google Classroom
                        </legend>
                        <p class="text-sm leading-5 text-gray-500">
                          Members are required to join the Google
                          Classroom (6vs6veb) as this will be the primary channel of communcation and the platform of chapter-organized service opportunities throughout the year.
                        </p>
                        <div class="mt-4">
                          <div class="flex items-center">
                            <input
                              required
                              type="radio"
                              class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                              id="classroomAgree"
                              name="classroomAgree"
                              checked={this.state.classroomAgree}
                              onChange={this.handleChanges}
                            />
                            <label for="push_everything" class="ml-3">
                              <span class="block text-sm leading-5 font-medium text-gray-700">
                                I agree
                              </span>
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset class="mt-6">
                        <legend class="text-base leading-6 font-medium text-gray-900">
                          Academic Integrity Agreement
                        </legend>
                        <p class="text-sm leading-5 text-gray-500">
                          The Plano East Honor Code “Plano East, in a relentless
                          effort to maintain high moral and ethical standards,
                          is intolerant of academic dishonesty in any form.
                          Cheating undermines both the integrity of the
                          perpetrator as well as that of the school. In
                          presenting an honor code of conduct based on
                          individual integrity and ethics, we aspire to create a
                          vision of what we would like our community to be. The
                          honor code intends to reduce unhealthy competition in
                          the school by shifting peer pressure away from
                          cheating and towards ethical behavior.”
                        </p>
                        <div class="mt-4">
                          <div class="flex items-center">
                            <input
                              required
                              type="radio"
                              class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                              id="acintegrityAgree"
                              name="acintegrityAgree"
                              checked={this.state.acintegrityAgree}
                              onChange={this.handleChanges}
                            />
                            <label for="push_everything" class="ml-3">
                              <span class="block text-sm leading-5 font-medium text-gray-700">
                                I agree
                              </span>
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <div class="mt-8 border-t border-gray-200 pt-5">
                    <div class="flex justify-end">
                      <span class="ml-3 inline-flex rounded-md shadow-sm">
                        <button class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                          Save
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
    );
  }
}
