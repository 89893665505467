import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase";

import logo from "../peshlogoSM.png";

// ***Page Description***
// Sign in page 
// ***********************
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSignIn = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        // if (result.user.email.toLowerCase().endsWith("mypisd.net")) {
        //     alert("Sorry, use an @mypisd.net Google account to continue.")
        // }
        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
  };

  render() {
    return (
      <div className="min-h-screen bg-white flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm">
            <div>
              <img className="h-12 w-auto" src={logo} alt="PESHNHS" />
              <h2 className="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
                Sign in to your account
                {/* The NHS Portal Will Open Soon */}
              </h2>
              <p className="mt-2 text-sm leading-5 text-gray-600 max-w">
                Please use your @mypisd.net Google account.
              </p>
            </div>

            <div className="mt-6">
              <div>
                <div className="mt-6">
                  <span className="block w-full rounded-md shadow-sm">
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                      onClick={this.handleSignIn}
                    >
                      Sign in with Google
                    </button>
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <div className="mt-6 relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm leading-5">
                  <span className="px-2 bg-white text-gray-500">
                    Find us on
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <div className="mt-3 grid grid-cols-3 gap-3">
                    <div>
                      <span className="w-full inline-flex rounded-md shadow-sm">
                        <a
                          href="mailto:peshnhs@gmail.com"
                          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                        >
                          <svg
                            class="w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                            ></path>
                          </svg>
                        </a>
                      </span>
                    </div>

                    <div>
                      <span className="w-full inline-flex rounded-md shadow-sm">
                        <a
                          href="https://twitter.com/nhspesh"
                          target="_blank"
                          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                        >
                          <svg
                            className="h-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                          </svg>
                        </a>
                      </span>
                    </div>

                    <div>
                      <span className="w-full inline-flex rounded-md shadow-sm">
                        <a
                          href="http://instagram.com/peshnhs"
                          target="_blank"
                          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                        >
                          <svg
                            viewBox="0 0 551.034 551.034"
                            className="h-5 h-5"
                            fill="currentColor"
                          >
                            <path
                              class="logo"
                              id="XMLID_17_"
                              d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156v222.722 c0,90.516,73.64,164.156,164.156,164.156h222.722c90.516,0,164.156-73.64,164.156-164.156V164.156 C551.033,73.64,477.393,0,386.878,0z M495.6,386.878c0,60.045-48.677,108.722-108.722,108.722H164.156 c-60.045,0-108.722-48.677-108.722-108.722V164.156c0-60.046,48.677-108.722,108.722-108.722h222.722 c60.045,0,108.722,48.676,108.722,108.722L495.6,386.878L495.6,386.878z"
                            />

                            <path
                              id="XMLID_81_"
                              fill="#555"
                              d="M275.517,133C196.933,133,133,196.933,133,275.516 s63.933,142.517,142.517,142.517S418.034,354.1,418.034,275.516S354.101,133,275.517,133z M275.517,362.6 c-48.095,0-87.083-38.988-87.083-87.083s38.989-87.083,87.083-87.083c48.095,0,87.083,38.988,87.083,87.083 C362.6,323.611,323.611,362.6,275.517,362.6z"
                            />

                            <circle
                              id="XMLID_83_"
                              fill="#555"
                              cx="418.306"
                              cy="134.072"
                              r="34.149"
                            />
                          </svg>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://lh3.ggpht.com/p/AF1QipPEV0wLwD5w5HCsl7S3M01c4BeiIri6_FSb4WcW=s2048"
            alt=""
          />
        </div>
      </div>
    );
  }
}
