import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase";

// ***Page Description***
// Page that pops up after refresh, unlocking website
// ***********************
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <main
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6 h-full"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div class="flex flex-col">
              <div class="bg-white shadow sm:rounded-lg mt-4">
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mt-4">
                  <svg
                    class="h-6 w-6 text-green-600"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    You're all set!
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500 px-4">
                      Your account has been activated and your registration has
                      been filed. You can access your profile information on the
                      sidebar. 
                    </p>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 mb-4">
                  <span class="flex w-full rounded-md shadow-sm"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
